const baseServerRoute = process.env.REACT_APP_API_URL;
const { default: authService } = require("./AuthenticationService");

async function getHeader() {
    const token = await authService.getAccessToken().then(function (token) { return token; });
    return new Headers({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }); // eslint-disable-line no-undef
}

export async function getGeoserverProxy() {
    const token = await authService.getAccessToken().then(function (token) { return token; });
    return [process.env.REACT_APP_API_URL + '/wms', token];
}

export async function getUserAssets(clientId) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/assets/${clientId}`); // eslint-disable-line no-undef
    return [request, options];
}

export async function getClientConfig(clientId) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/client/config/${clientId}`); // eslint-disable-line no-undef
    return [request, options];
}

export async function getAssetLayerImpactSeverity(clientId, assetLayerId) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/assets/severity/${clientId}/${assetLayerId}`); // eslint-disable-line no-undef
    return [request, options];
}

export async function getAssetLayerData(clientId, assetLayerId) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/assets/data/${clientId}/${assetLayerId}`); // eslint-disable-line no-undef
    return [request, options];
}

export async function getClimateRegistry(clientId) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/climate/${clientId}`); // eslint-disable-line no-undef
    return [request, options];
}

export async function getClimateLayerDisplayInfo(displayType, displayId) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/climate/displayInfo/${displayType}/${displayId}`); // eslint-disable-line no-undef
    return [request, options];
}

export async function getRiskScores(clientId, assetLayerId, source, timeframe, scenario, parameter) {
    const headers = await getHeader();
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        cache: 'default'
    };
    const request = new Request(`${baseServerRoute}/assets/riskscores/${clientId}/${assetLayerId}/${source}/${timeframe}/${encodeURIComponent(scenario)}/${parameter}`); // eslint-disable-line no-undef
    return [request, options];
}