import React from 'react'
import {  MapNav, MapNavbarContainer, MapNavLogo, MapNavMenu } from '../Map/MapElements'
import { SettingsDropdown } from './settingsDropdown';


export default function SettingsNav() {

  return (
    <MapNav style={{position:'fixed', top:0, width:'100%'}}>
        <MapNavbarContainer>
            <MapNavLogo to="/" >CRIVA</MapNavLogo>
            <SettingsDropdown/>
        </MapNavbarContainer>
    </MapNav>
  )
}


