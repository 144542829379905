import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, Button } from '@mui/material';



const ClimateLayerMenu = (props) => {

    const { displayMenu, climateLayers, currentTimeframe, currentScenario, currentClimateParameter } = props;
    const [noParameterError, setNoParameterError] = React.useState(false);
    const [availableClimateLayers, setAvailableClimateLayers] = useState([]);

    const displayStyle = displayMenu ? 'flex' : 'none';


    useEffect(() => {
        // Getting available climate parameters for current timeframe and scenario
        updateClimateParameterList(currentTimeframe, currentScenario, currentClimateParameter);
    }, []);



    const menuStyle = {
        padding: '5px 10px 5px',
        display: displayStyle, 
        flexDirection: 'columm',
        width: 'fit-content',
        position: 'fixed',
        top: '50px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        minWidth: '750px', 
        maxHeight: '50vh', 
        overflowY: 'auto'
    };

    const filterColumnsStyle = {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        margin: '10px 20px',
        bgcolor: 'background.paper'
    };

    const columnStyle = {
        minWidth: '130px',
        margin: '0 20px',
    }

    const resetFilters = () => {
        if (noParameterError) { // resetting no parameter if it is present
            setNoParameterError(false);
        }
        updateClimateParameterList('Historical', 'RCP4.5/SSP2-4.5', 'none');
    }


    const handleTimeframeChange = (event) => {
        updateClimateParameterList(event.target.value, currentScenario, currentClimateParameter);
    };

    const handleScenarioChange = (event) => {
        updateClimateParameterList(currentTimeframe, event.target.value, currentClimateParameter);
    };

    const handleClimateParameterChange = (event) => {
        if (noParameterError) { // resetting no parameter if it is present
            setNoParameterError(false);
        }
        props.updateFilter(currentTimeframe, currentScenario, event.target.value);
    };

    const updateClimateParameterList = (timeframe, scenario, parameter_layer_id) => {

        if (noParameterError) { // resetting no parameter if it is present
            setNoParameterError(false);
        }

        if (parameter_layer_id != 'none') { // parameter already selected

            // current paramter selected climate layer info for previously selected timeframe and scenario 
            const selectedLayerInfo = climateLayers.filter((layer) => layer.id == parameter_layer_id);

            if (selectedLayerInfo.length > 0) {

                // Check to see if that selected parameter has selected timeframe and scenario
                const matchingLayer = climateLayers.findIndex((layer) => {
                    const matchingScenario = layer.scenario.toLowerCase() == scenario.toLowerCase() ? true : false;
                    const matchingTimeframe = layer.timeframe.toLowerCase() == timeframe.toLowerCase() ? true : false;
                    const matchingParameter = layer.data_parameter.toLowerCase() == selectedLayerInfo[0].data_parameter.toLowerCase() ? true : false;
                    return matchingScenario && matchingTimeframe && matchingParameter;
                });
    
                if (matchingLayer == -1) { // layer parameter not found for selected timeframe and scenario
                    props.updateFilter(timeframe, scenario, 'none'); // reseting climate parameter option
                    setNoParameterError(true);
                } else {
                    props.updateFilter(timeframe,scenario,climateLayers[matchingLayer].id);
                }

            }

        } else {
            props.updateFilter(timeframe, scenario, parameter_layer_id);
        }

        const layers = climateLayers.filter((layer) => layer.scenario.toLowerCase() == scenario.toLowerCase() && layer.timeframe.toLowerCase() == timeframe.toLowerCase() && layer.display_type != null);
        layers.sort(function(a, b) {
            return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
        });

        layers.push({'id': 'none', 'timeframe': "", 'scenario': "", "title": "No Climate Parameter Selected"});
        setAvailableClimateLayers(layers);
        
    }
    

    return (
        <Paper sx={menuStyle}>
            <Box sx={filterColumnsStyle}>

                <Box sx={columnStyle}>
                    <FormControl>
                        <FormLabel component="legend">Timeframe</FormLabel>
                        <RadioGroup size="small" value={currentTimeframe} onChange={handleTimeframeChange}>
                            <FormControlLabel value="Historical" control={<Radio size="small" />} label={<Typography variant="body2">Historical</Typography>} />
                            <FormControlLabel value="Near-Term" control={<Radio size="small" />} label={<Typography variant="body2">Near-Term</Typography>} />
                            <FormControlLabel value="Mid-Term" control={<Radio size="small" />} label={<Typography variant="body2">Mid-Term</Typography>} />
                            <FormControlLabel value="Far-Term" control={<Radio size="small" />} label={<Typography variant="body2">Far-Term</Typography>} />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box sx={columnStyle}>
                    <FormControl>
                        <FormLabel component="legend">Scenario</FormLabel>
                        <RadioGroup value={currentScenario} sx={{ lineHeight: '40px'}} onChange={handleScenarioChange}>
                            <FormControlLabel value="Historical" control={<Radio size="small" />} label={<Typography variant="body2">Historical</Typography>} />
                            <FormControlLabel value="RCP4.5/SSP2-4.5" control={<Radio size="small" />} label={<Typography variant="body2">RCP4.5/SSP2-4.5</Typography>} />
                            <FormControlLabel value="RCP8.5/SSP5-8.5" control={<Radio size="small" />} label={<Typography variant="body2">RCP8.5/SSP5-8.5</Typography>} />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box sx={[columnStyle, {minWidth: '280px'}]}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Climate Parameter</FormLabel>
                        <Select value={currentClimateParameter} onChange={handleClimateParameterChange}>
                            {availableClimateLayers.map((layer,index) => (
                                <MenuItem key={index + 'parameter'} value={layer.id}><Typography variant="body2">{layer.title}</Typography></MenuItem>
                            ))}
                        </Select>
                        { noParameterError && (<Typography varaint="subtitle" sx={{ color: "#d60202"}}>Previous parameter not available for that timeframe and scenario.</Typography>)}
                    </FormControl>
                </Box>
                
            </Box>
            <Box sx={{ position: 'absolute', bottom: 10, right: 20}}>
                <Button variant="outlined" onClick={() => resetFilters()}>Reset</Button>
            </Box>
        </Paper>
    )

}


export default ClimateLayerMenu;
