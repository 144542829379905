import React, { useRef, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import dataProvider from './testData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close'
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

import TreeComponent from '../TreeView';
import authService from '../../utils/AuthenticationService';
import { main_container, main_container_last_step, step_container, tree_column_flex, view_results_container, step_header, step_header_center, step_subheader, card, centered_card, centered_card_margin, menu_button, custom_menu_dropdown, menu_button_container, menu_dropdown_options, radio_button_options, table_header, table_container, table_placeholder, tab_panel_container, step_icon } from './styles';
import { getAssetLayerImpactSeverity, getAssetLayerData } from '../../utils/apiRequestBuilder';

const AnalysisPanel = (props) => {


    const [activeStep, updateAnalysisStep] = React.useState(0);
    const [completedAnalysisSteps, updateCompletedSteps] = React.useState([false,false,false,false,false]);
    const [userAssetLayers, updateUserAssetLayers] = React.useState(props.assets);
    const [selectedAssets, updateSelectedAssets] = React.useState([]);
    const [selectedAssetShowing, updateAssetShowing] = React.useState(null);
    const [impactSeverity, updateImpactSeverity] = React.useState([]);
    const [climateRegistry, updateClimateRegistry] = React.useState(props.climateRegistry);
    const [climateLayers, updateClimateLayers] = React.useState([]);
    const [climateTimeframe, updateClimateTimeframe] = React.useState(null);
    const [climateScenario, updateClimateScenario] = React.useState(null);
    const [climateParameters, updateClimateParameters] = React.useState([]);

    const custom_table_headers = { // used to add custom column names mainly for impact severity review table
        'annual_1_day_maximum_precipitation': 'Annual 1 Day Max Precip',
        'warm_season_1_day_maximum_precipitation': 'Warm Season 1 Day Max Precip',
        'cold_season_1_day_maximum_precipitation': 'Cold Season 1 Day Max Precip',
        'hundred_year_flooding': '100y Flooding',
        'one_hundred_year_flood': '100y Flooding',
        'days_greater_30_c': 'Days > 30°C',
        'days_less_negative_25_c': 'Days < -25°C',
        'heat_waves_3plus_days_greater_30c': 'Heat Waves (3 days > 30°C)',
        'temp_days_greater_than_30': 'Temp. # Days > 30°C',
        'temp_days_less_than_25': 'Temp. # Days < -25°C'
    }


    function camelCaseToSentenceCase(s) {
        if (s == null) {
          return;
        }
        const result = s.replace(/([A-Z])/g, "$1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    function capitalizeAllWordsInString(str) {

        const words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);  
        }
        return words.join(' ');
    }

    function formatStringValue(value) {
        if (typeof value === 'string') {
            const camelCase = camelCaseToSentenceCase(value);
            const formatedValue = camelCase.replaceAll('_', ' ');
            // capitalizing each word in string
            const capitalizedValue = capitalizeAllWordsInString(formatedValue);
            return capitalizedValue;
        } else {
            return value;
        }

    }

    function formatCellValue(value) {
        if (typeof value === 'string') {
            const camelCase = camelCaseToSentenceCase(value);
            const formatedValue = camelCase.replaceAll('_', ' ');
            return formatedValue;
        } else {
            return value;
        }
    }

    function sortAssetMetadataKeys(definition) {

        const sortedDefinition = definition.sort((a, b) => (a.column_priority > b.column_priority) ? 1 : -1);
        const sortedMetadataKeys = [];

        sortedDefinition.forEach((assetProperty) => {
            if (!assetProperty.is_hidden) {
                sortedMetadataKeys.push(assetProperty.column_name);
            }
        });

        return sortedMetadataKeys;

    }

    const renderTableHeader = (data, definition=[]) => {
        const tableHeaderKeys = definition.length > 0 ? sortAssetMetadataKeys(definition) : Object.keys(data[0]);
        const filteredOutId = tableHeaderKeys.filter((headerKey) => headerKey != 'id'); // removing id column from table
        const header = filteredOutId.map((header, index) => {
                const definitionIndex = definition.length > 0 ? definition.findIndex((property) => property.column_name == header) : null;
                const has_custom_table_heading = custom_table_headers.hasOwnProperty(header) ? true : false;
                const formatedHeader = has_custom_table_heading ? custom_table_headers[header] : definitionIndex ? formatStringValue(definition[definitionIndex].column_alias) : formatStringValue(header);
                return (
                    <TableCell key={index} sx={table_header}>{formatedHeader}</TableCell>
                )
            });
        return header;
    }

    const renderTableRows = (data, definition=[]) => {
        const tableHeaderKeys = definition.length > 0 ? sortAssetMetadataKeys(definition) : Object.keys(data[0]);
        const filteredOutId = tableHeaderKeys.filter((headerKey) => headerKey != 'id'); // removing id column from table
        const sortedDataRows = data.sort((a, b) => (a.id > b.id) ? 1 : -1); // id column is hidden from being displayed
        const rows = sortedDataRows.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{'&:last-child td, &:last-child th': { border: 0 }, backgroundColor: rowIndex % 2 ? 'white' : '#f6fafd', padding:0 }}
            >
              {filteredOutId.map((column, columnIndex) => {
                  if (column=='website'){
                    return(<TableCell key={columnIndex} component='a' href={row[column]} target='_blank' align="center" sx={{padding:1}}>Link</TableCell>)
                  }
                  return(
                    <TableCell key={columnIndex} align="center" sx={{ whiteSpace: 'nowrap'}} >
                        <div style={{ lineHeight: '1.0em', height: '1.5em', overflow: 'hidden'}}>
                            {formatCellValue(row[column])}
                        </div>
                    </TableCell>
                  )
              })}
            </TableRow>
        ));

        return rows;
    }

    const TimeframeOptions = () => {

        const [uniqueTimeframes, updateUniqueTimeframes] =  React.useState([]);
        const [initialLoad, updateInitialLoad] = React.useState(true);
        const timeframeSortOrder = { 'historical': 0, 'near-term': 1, 'mid-term': 2, 'far-term': 3 };

        function customSort(arr, sortOrder) {
            // Create a map to store the index of each string in the sortOrder object
            const sortOrderMap = {};
            Object.keys(sortOrder).forEach((key, index) => {
                sortOrderMap[key] = index;
            });
        
            // Custom sorting function
            function compare(a, b) {
                const stringA = a.toLowerCase();
                const stringB = b.toLowerCase();

                // Check if both strings are in the sortOrder object
                if (sortOrder.hasOwnProperty(stringA) && sortOrder.hasOwnProperty(stringB)) {
                    // Compare based on their indices in the sortOrder object
                    return sortOrderMap[stringA] - sortOrderMap[stringB];
                } else if (sortOrder.hasOwnProperty(stringA)) {
                    // If only 'a' is in sortOrder, it should come before 'b'
                    return -1;
                } else if (sortOrder.hasOwnProperty(stringB)) {
                    // If only 'b' is in sortOrder, it should come after 'a'
                    return 1;
                } else {
                    // If neither 'a' nor 'b' is in sortOrder, maintain their original order
                    return 0;
                }
            }
        
            // Sort the array using the custom sorting function
            return arr.sort(compare);
        }
        

        useEffect(() => {
            if (initialLoad) {
                const timeframes = [];
                for (const layerIndex in climateRegistry) {
                    const layer = climateRegistry[layerIndex];
                    const index = timeframes.findIndex(timeframe => timeframe == layer.timeframe);
                    if (index == -1) {
                        timeframes.push(layer.timeframe);
                    }
                }
                const sortedTimeframes = customSort(timeframes, timeframeSortOrder);
                updateUniqueTimeframes(sortedTimeframes);
                updateInitialLoad(false);
            }
        }, []);


        function handleTimeframeChange(event) {
            updateClimateTimeframe(event.target.value);
            const completedSteps = [...completedAnalysisSteps];
            if (!completedSteps[0] && climateScenario != null) {
                completedSteps[0] = true;
                updateCompletedSteps(completedSteps);
            }
        }


        return (
            <div style={card}>
                <Typography variant="h6" style={step_header} gutterBottom>
                    Select Climate Timeframe
                </Typography>
                <Typography variant="p">
                    Choose a timeframe for this analysis.  The timeframe and climate change scenario you choose will determine the climate prediction layers available in subsequent steps.
                </Typography>
                <FormControl>
                    <Typography variant="subtitle1" style={step_subheader} gutterBottom>Timeframe:</Typography>
                    <div style={{border:'.5px #868e96 solid', borderRadius:'10px', background:'#f6fafd', height:'210px', width:'360px', overflowY:'auto',}}>
                        <RadioGroup
                            aria-labelledby="timeframe-radio-buttons-group-label"
                            name="radio-buttons-group"
                            defaultValue={climateTimeframe}
                            onChange={handleTimeframeChange}
                        >
                            {uniqueTimeframes.map((timeframe, index) => {
                                return (
                                    <FormControlLabel key={index} sx={radio_button_options} value={timeframe} control={<Radio />} label={timeframe} />
                                )
                            })}
                        </RadioGroup>
                    </div>
                </FormControl>
            </div>
        );
    }

    const ClimateScenarios = () => {

        const [uniqueScenarios, updateUniqueScenarios] =  React.useState([]);
        const [initialLoad, updateInitialLoad] = React.useState(true);

        useEffect(() => {
            if (initialLoad) {
                const scenarios = [];
                for (const layerIndex in climateRegistry) {
                    const layer = climateRegistry[layerIndex];
                    const index = scenarios.findIndex(scenario => scenario == layer.scenario);
                    if (index == -1 && layer.timeframe == climateTimeframe) {
                        scenarios.push(layer.scenario);
                    }
                }
                updateUniqueScenarios(scenarios.sort());
                updateInitialLoad(false);
            }
        }, []);

        function handleScenarioChange(event) {
            updateClimateScenario(event.target.value);
            const completedSteps = [...completedAnalysisSteps];
            if (!completedSteps[0] && climateTimeframe != null) {
                completedSteps[0] = true;
                updateCompletedSteps(completedSteps);
            }
        }

        return (
            <div style={{...card, visibility:climateTimeframe != null ? 'visible' :'hidden'}}>
                <Typography variant="h6" style={step_header} gutterBottom>
                    Select Climate Scenario
                </Typography>
                <Typography variant='p'>
                    Choose a climate change scenario for this analysis. Two Shared Socioeconomic Pathways (SSPs) are available, representing the “Middle of the Road” (SSP2-4.5) and “Worst-Case Scenario” (SSP5-8.5). For more information on SSPs, click <a href='https://www.dkrz.de/en/communication/climate-simulations/cmip6-en/the-ssp-scenarios' target='_blank'>here</a>.
                </Typography>
                <FormControl>
                    <Typography variant="subtitle1" style={step_subheader} gutterBottom>Climate Scenario:</Typography>
                    <div style={{border:'.5px #868e96 solid', borderRadius:'10px', background:'#f6fafd', height:'186px', width:'360px', overflowY:'auto'}}>
                        <RadioGroup
                            aria-labelledby="scenario-radio-buttons-group-label"
                            name="scenario-radio-buttons-group"
                            defaultValue={climateScenario}
                            onChange={handleScenarioChange}
                        >
                            {uniqueScenarios.map((scenario, index) => {
                                return (
                                    <FormControlLabel key={index} sx={radio_button_options} value={scenario} control={<Radio />} label={scenario} />
                                )
                            })}
                        </RadioGroup>
                    </div>
                </FormControl>
            </div>

        );
    }

    const RenderClimateScenarioAndTimeFrame = () => {

        return(
            <Box sx={step_container}>
                <div style={{display:'flex', justifyContent:'space-evenly', alignContent:'space-around'}}>
                    <TimeframeOptions/>
                    <ClimateScenarios/> 
                </div>
            </Box>
        )
    }

    const RenderClimateParameters = () => {

        const [climateLayerParameters, updateClimateLayerParameters] = React.useState([]);

        useEffect(() => {
            if (climateLayerParameters.length < 1) {
                createClimateLayerTree();
            }
        }, []);

        function idf_hierarchy_map(name) { // Used to map the menu portions of the idf layers to a value since sorting them alphabetically will not cause them to be in the desired order

            const name_map = {
                '2 Year': 2,
                '5 Year': 5,
                '10 Year': 10,
                '20 Year': 20,
                '25 Year': 25,
                '50 Year': 50,
                '100 Year': 100,
                '5 min': 5,
                '10 min': 10,
                '15 min': 15,
                '30 min': 30,
                '1 h': 60,
                '2 h': 120,
                '6 h': 360,
                '12 h': 720,
                '24 h': 1440
            };

            if (name_map[name]) {
                return name_map[name];
            } else {
                return null;
            }
        }


        function sortClimateParameters(parametersTree) {

            parametersTree.sort((a, b) => {

                const nameA = idf_hierarchy_map(a.name) != null ? idf_hierarchy_map(a.name) : a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = idf_hierarchy_map(b.name) != null ? idf_hierarchy_map(b.name) : b.name.toUpperCase(); // ignore upper and lowercase

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names are probably be equal
                return 0;
            });

            //Recursively sort the children objects if they exist
            for (let tree of parametersTree) {
                if (tree.children && tree.children.length > 0) {
                    sortClimateParameters(tree.children);
                }
            }

            return parametersTree;

        }

        async function createClimateLayerTree() {
            let climateLayers = [];
            for (let i = 0; i < climateRegistry.length; i++) {
                const climateLayer = climateRegistry[i];
                if (!climateLayer.is_active) { continue; };
                if (climateLayer.scenario != climateScenario || climateLayer.timeframe != climateTimeframe) {
                    continue;
                }
            
                let menuArr = climateLayer.menu.split("/");
                menuArr.unshift('All'); // adding this hierarchy to group all layers under one heading
                
                // removing climate scenario and timeframe from menu since user already selected it
                const scenario_possibilities = climateScenario.split("/");
                let filteredMenuArr = menuArr.filter((menuItem) => !menuItem.includes(climateTimeframe) && !menuItem.includes(scenario_possibilities[0]) && !menuItem.includes(scenario_possibilities[1]) );
                
                if (filteredMenuArr.length == 2) { // returning last item in menu tree only if filtered menu array is length 2 after removing timeframe and scenario
                    filteredMenuArr = filteredMenuArr.slice(0,1);
                }

                let currentTreeLevel = climateLayers;
                while (filteredMenuArr.length > 0) { // creating a tree structure dropwdown menu
                    const node = filteredMenuArr.shift();
                    const treeNodeIndex = currentTreeLevel.findIndex(treeNode => treeNode.id == node.toLowerCase());
                    if (treeNodeIndex == -1) { // node is not there
                        // add to current tree level
                        const newNode = { id: node.toLowerCase(), name: node, hierarchy: climateLayer.menu };
                        currentTreeLevel.push(newNode);
                        newNode.children = [];
                        currentTreeLevel = newNode.children;
                    } else {
                        if (!currentTreeLevel[treeNodeIndex].hasOwnProperty('children')) {
                            currentTreeLevel[treeNodeIndex].children = [];
                        }
                        const nodeChildren = currentTreeLevel[treeNodeIndex].children;
                        currentTreeLevel = nodeChildren;
                    }
                }
                const nodeId = climateLayer.id + climateLayer.title.toLowerCase();
                currentTreeLevel.push({ id: nodeId, name: climateLayer.title, hierarchy: climateLayer.menu });            
            }

            const sortedParameters = sortClimateParameters(climateLayers);
            updateClimateLayerParameters(sortedParameters);
        }

        function updateSelectedParameters(nodes) {

            const selectedLayers = [];

            for (const nodeIndex in nodes) {
                const node = nodes[nodeIndex];
                const layerIndex = climateRegistry.findIndex((layer) => (layer.id + layer.title.toLowerCase()) == node.id);
                if (layerIndex != -1) {
                    selectedLayers.push(climateRegistry[layerIndex]);
                }
            }

            const completedSteps = [...completedAnalysisSteps];

            if (selectedLayers.length == 0) {
                completedSteps[1] = false;
            } else {
                completedSteps[1] = true;
            }
            updateClimateParameters(selectedLayers);
            updateCompletedSteps(completedSteps);

        }

        return (
            <Box sx={step_container}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={centered_card}>
                        <Typography variant="h6" style={step_header_center} gutterBottom>
                            Select Climate Parameters
                        </Typography>
                        <Typography variant="p" style={{marginBottom:'5px', textAlign:'center'}}>
                            Select the climate parameters that you’d like to apply in your analysis. These will provide the Climate Probability or Likelihood of Occurrence scores, which illustrate how the environment may change for each of your asset locations.
                        </Typography>
                    </div>
                    <div style={card}>
                        <Typography variant="subtitle1" style={{marginTop:'10px',fontFamily:'Jost', fontWeight: 'bold',}} gutterBottom>Climate Parameters:</Typography>
                        <Box sx={tree_column_flex}>
                            {climateLayerParameters.map((tree, index) => (
                                <TreeComponent key={index} data={[tree]} updateSelectedNodes={updateSelectedParameters} />
                            ))}
                        </Box>
                    </div>
                </div>
            </Box>
        );
    };

    const RenderAssets = () => {
        const [menuStatus, toggleMenu] = React.useState(false);
        const toggleDropdown = () => toggleMenu(!menuStatus);
        const [loadingAssets, updateLoadingAssets] = React.useState(false);
        const [currentTab, updateCurrentTab] = React.useState(selectedAssetShowing); // need this local state variable as well to facilitate re-render


        async function handleAssetSelection(layer) {
            const completedSteps = [...completedAnalysisSteps];
            const layerIndex = selectedAssets.findIndex(assetLayer => assetLayer.id == layer.id);
            if (layerIndex == -1) { // adding asset
                updateLoadingAssets(true);
                const user = await authService.getUser();
                const userTenantId = user.profile.tenant_id
                const [request, options] = await getAssetLayerData(userTenantId, layer.id);
                const response = await fetch(request, options);
                if (response.status === 200) {
                    const json = await response.json();
                    const layerCopy = JSON.parse(JSON.stringify(layer));
                    layerCopy['metaData'] = json[0];
                    layerCopy['definition'] = json[1];

                    completedSteps[2] = true;
                    updateAssetShowing(`${layer.id}`);
                    updateLoadingAssets(false);
                    updateCompletedSteps(completedSteps);
                    updateSelectedAssets([...selectedAssets, layerCopy]);                
                } else {
                    //handle error here
                }
            } else { // removing asset
                const assetsCopy = [...selectedAssets];
                assetsCopy.splice(layerIndex, 1);
                if (assetsCopy.length == 0) {
                    completedSteps[2] = false;
                } else {
                    updateAssetShowing(`${assetsCopy[assetsCopy.length -1].id}`); // updating tab to previous tab
                }
                updateCompletedSteps(completedSteps);
                updateSelectedAssets(assetsCopy);
            }
        }

        function assetLayerIsSelected(layer) {
            const selectedLayerIndex = selectedAssets.findIndex(assetLayer => assetLayer.id == layer.id);
            if (selectedLayerIndex == -1) {
                return false;
            } else {
                return true;
            }
        }

        function handleTableTabChange(newTabEvent, newValue) {
            updateCurrentTab(newValue);
        }

        return (
            <Box sx={step_container}>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={ selectedAssets.length == 0 ? {...centered_card} : {...centered_card_margin, ...centered_card}}>
                        <Typography variant="h6" style={step_header_center} gutterBottom>
                            Select Asset Layer
                        </Typography>
                        <div style={menu_button_container}>
                            <Button onClick={toggleDropdown} sx={menu_button} endIcon={menuStatus ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}>
                                { selectedAssets.length > 0 ? selectedAssets[selectedAssets.length - 1].title : 'Asset Layers' }
                            </Button>
                            <Paper sx={[custom_menu_dropdown, {display: menuStatus ? 'flex' : 'none'}]}>
                                <FormGroup>
                                    {userAssetLayers.map((layer) => {
                                        return (
                                            <FormControlLabel sx={menu_dropdown_options} control={<Checkbox checked={assetLayerIsSelected(layer)} onChange={() => handleAssetSelection(layer)} />} key={layer.asset_registry_id + layer.title} label={layer.title} />
                                        )
                                    })}
                                </FormGroup>
                            </Paper>
                        </div>
                        <div>
                            {loadingAssets ? <CircularProgress /> : null}
                        </div>
                    </div>

                    {selectedAssets.length == 0 && (
                        <TableContainer sx={table_container} component={Paper}>
                            <h3 style={table_placeholder}>{selectedAssets.length > 0 ?'': 'No asset layer selected'}</h3>
                        </TableContainer>
                    )}
                    
                    
                    {selectedAssets.length > 0 && (
                        <TabContext value={currentTab}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={handleTableTabChange} aria-label="assetsTab">
                                        {selectedAssets.map((asset, index) => {
                                            return (
                                                <Tab label={asset.title} value={`${asset.id}`} />
                                            )
                                        })}
                                    </TabList>
                                </Box>

                                <TableContainer sx={table_container} component={Paper}>


                                        {selectedAssets.map((asset, aIndex) => {
                                            return (
                                                <TabPanel value={`${asset.id}`} sx={tab_panel_container} index={aIndex}>
                                                    <Table stickyHeader size="small" aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                {renderTableHeader(asset.metaData, asset.definition)}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                                {renderTableRows(asset.metaData, asset.definition)}
                                                        </TableBody>
                                                    </Table>
                                                </TabPanel>
                                            )
                                        })}

                                    
                                </TableContainer>
                            </div>
                        </TabContext>
                    )}
                    

                </div>
            </Box>
        );
    }

    const RenderImpactSeverity = () => {

        useEffect(() => {
            if (impactSeverity.length < 1) {
                fetchAssetImapctSeverity();
            }
        }, []);

        const [currentTab, updateCurrentTab] = React.useState('0');

        async function fetchAssetImapctSeverity() {
            const user = await authService.getUser();
            const userTenantId = user.profile.tenant_id;
            const currentImpactSeverities = [...impactSeverity];
            for (let i = 0; i < selectedAssets.length; i++) {
                const [request, options] = await getAssetLayerImpactSeverity(userTenantId, selectedAssets[i].id);
                const response = await fetch(request, options);
                if (response.status === 200) {
                    const json = await response.json();
                    json['assetTitle'] = selectedAssets[i].title;
                    const completedSteps = [...completedAnalysisSteps];
                    completedSteps[3] = true;
                    completedSteps[4] = true;
                    updateCompletedSteps(completedSteps);
                    currentImpactSeverities.push(json);
                } else {
                    //handle error here
                }

            }

            updateImpactSeverity(currentImpactSeverities);

        }

        function handleTableTabChange(newTabEvent, newValue) {
            updateCurrentTab(newValue);
        }

        return (
            <Box sx={step_container}>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{...centered_card_margin, ...centered_card}}>
                    <Typography variant="h6" style={step_header_center} gutterBottom>
                        Review Impact Severity
                    </Typography>
                    <div style={{marginBottom:'5px', textAlign:'center'}}>
                        The Severity of Impacts Scores reflect how sensitive your assets are to changes in each of the selected climate parameters.
                    </div>
                </div>
                    {impactSeverity.length == 0 ? 
                        <CircularProgress /> 
                        :
                        
                        <TabContext value={currentTab}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleTableTabChange} aria-label="assetsTab">
                                        {impactSeverity.map((severity, index) => {
                                            return (
                                                <Tab label={severity.assetTitle} value={`${index}`} key={index} />
                                            )
                                        })}
                                    </TabList>
                                </Box>

                                <TableContainer sx={table_container} component={Paper}>

                                    {impactSeverity.map((severity, index) => {
                                        return (
                                            <TabPanel value={`${index}`} sx={tab_panel_container} key={index}>
                                                <Table stickyHeader size="small" aria-label="impact severity table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {renderTableHeader(severity)}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                            {renderTableRows(severity)}
                                                    </TableBody>
                                                </Table>
                                            </TabPanel>
                                        )
                                    })}
                                </TableContainer>
                            </div>

                        </TabContext>
                    }
                </div>
            </Box>
        );
    }

    const RenderRiskSummary = () => {
        return (
            <Box sx={view_results_container}>
                <div>
                <Typography variant="h6" style={{fontWeight: 'bold'}} gutterBottom>
                    The analysis is complete
                </Typography>

                <Typography variant="subtitle1" gutterBottom>
                    Clicking “View Results” will show the assets on the map, along with the results panel.
                    You can interact with the map features and results panel to explore the calculated climate risks.
                </Typography>

                </div>
            </Box>
        );
    }

    const analysisSteps = useMemo(() => {
        const steps = [
            { label: 'Select Timeframe & Scenario', component: RenderClimateScenarioAndTimeFrame},
            { label: 'Select Climate Parameters', component: RenderClimateParameters },
            { label: 'Select Asset Layer', component: RenderAssets },
            { label: 'Review Impact Severity', component: RenderImpactSeverity }
        ];

        return steps;
    }, [climateScenario, climateTimeframe, selectedAssets, impactSeverity]);

    const analysisStepButtonTitles = ['Next','Next','Next','Run Analysis','View Results'];

    function handleNextStep() {
        //validation

        if (activeStep == analysisSteps.length) { // All analysis steps completed
            props.closePanel(selectedAssets, climateParameters);
        } else {
            updateAnalysisStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    function handlePrevStep() {
        //reset values for current step if needed

        updateAnalysisStep((prevActiveStep) => prevActiveStep - 1);
    }

    const renderAnalysisStep = () => {
        if (activeStep > analysisSteps.length -1) {
            return (
                <RenderRiskSummary />
            );

        } else {
            const StepComponent = analysisSteps[activeStep].component;
            return (<StepComponent />);
        }
    };


    return (
        <div style={activeStep == analysisSteps.length ? main_container_last_step : main_container}>

            <div style={{ backgroundColor: "#000", height: '50px', padding: '10px', display: "flex", justifyContent: "space-between", alignContent:'center'}}>
                <Typography variant='h6' style={{ color: "#fff", marginLeft: '10px', height:'100%', verticalAlign:'middle'}}>CRIVA Climate Risk Analysis</Typography>
                <CloseIcon onClick={props.closeAnalysisPanel} style={{color:'white', height:'100%', cursor:'pointer'}}/>
            </div>

            <div style={{ display:'flex', flexDirection:'column',flexGrow:1, paddingTop:15}}>
                {activeStep == analysisSteps.length ? null : 
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {analysisSteps.map((step) => (
                        <Step key={step.label} sx={step_icon}>
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                }
                
                {renderAnalysisStep()}  
                
            </div>
            <div style={{ position: 'absolute', bottom: '20px', right: '20px'}}>
                    <Button onClick={handlePrevStep} disabled={activeStep == 0}>{'Previous'}</Button>
                    <Button onClick={handleNextStep} disabled={!completedAnalysisSteps[activeStep]} variant="contained">{analysisStepButtonTitles[activeStep]}</Button>
            </div>       
        </div>
    )
}

export default AnalysisPanel;