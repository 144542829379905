import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { DataGrid, GridToolbarFilterButton, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { fontWeight } from '@mui/system';


const details_header = { 
    backgroundColor: "#000",
    height: '40px',
    padding: '15px 10px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

const detailedRiskModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
};

const metadataDiv = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
};

const metadataTitle = {
    display: 'block',
    padding: '3px 0'
}

const metadataValue = {
    display: 'block'
}

const detailTablesStyle = {
    '& .risk_green': {
        backgroundColor: 'rgba(168, 208, 141, .5)',
    },
    '& .risk_blue': {
        backgroundColor: 'rgba(0, 176, 240, .5)',
    },
    '& .risk_5': {
        backgroundColor: 'rgba(255, 230, 153, .5)',
    },
    '& .risk_yellow': {
        backgroundColor: 'rgba(255, 255, 0, .5)',
    },
    '& .risk_orange': {
        backgroundColor: 'rgba(255, 119, 0, .5)',
    },
    '& .risk_red': {
        backgroundColor: 'rgba(255, 0, 0, .5)',
    },
    '& .row_header':{
        backgroundColor: '#f6fafd',
        fontWeight:'bold',
        textAlign:'right'
    },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#f6fafd",
        borderBottom:'3px #ebebeb solid',
        overflowWrap:'normal'
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight:'bold',
        overflow: "visible",
        lineHeight: "1.43rem",
        whiteSpace: "normal",
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        border:'1px #888 solid'
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
        backgroundColor: '#888'
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
        width: 10,
        height: 10
    },
    "& .MuiDataGrid-columnHeaderTitleContainer":{justifyContent:'center', textAlign: 'center'},
    "& .MuiDataGrid-cell":{ justifyContent:'center', textAlign: 'center', whiteSpace: 'normal !important' }
}



const DetailsModal = (props) => {
    const [currentTab, updateCurrentTab] = React.useState("1");
    const allRiskData = props.riskData;
    const selectedAsset = JSON.parse(JSON.stringify(props.asset.metadata));
    const metadataDefinitions = JSON.parse(JSON.stringify(props.asset.definition));
    const selectedAssetLayerInfo = JSON.parse(JSON.stringify(props.asset.asset_layer_info));
    const selectedClimateScenario = allRiskData.length > 0 ? allRiskData[0].scenario : ''; 
    const selectedTimeframe = allRiskData.length > 0 ? allRiskData[0].timeframe : ''; 
    

    function handleTabChange(newTabEvent, newValue) {
        updateCurrentTab(newValue);
    }

    function sortAssetMetadataKeys(definition) {

        const sortedDefinition = definition.sort((a, b) => (a.column_priority > b.column_priority) ? 1 : -1);
        const sortedMetadataKeys = [];
        sortedDefinition.forEach((assetProperty) => {
            if (!assetProperty.is_hidden) {
                sortedMetadataKeys.push(assetProperty.column_name);
            }
        });

        return sortedMetadataKeys;
    }

    function camelCaseToSentenceCase(s) {
        if (s == null) {
          return;
        }
        const finalResult = s.charAt(0).toUpperCase() + s.slice(1);
        return finalResult;
    }

    function formatStringValue(value) {
        if (typeof value === 'string') {
            const camelCase = camelCaseToSentenceCase(value);
            const formatedValue = camelCase.replaceAll('_', ' ');
            return formatedValue;
        } else {
            return value;
        }
    }

    function isFloat(n) {
        return Number(n) === n && n % 1 !== 0;
    }

    function formatRiskValue(value) {
        if (isFloat(value)) {
            return value.toFixed(2);
        } else {
            return value;
        }
    }

    function formatMetadataKey(key) {
        const definitionIndex = metadataDefinitions.findIndex((definition) => definition.column_name == key);
        const metadata_alias = metadataDefinitions[definitionIndex].column_alias;
        return formatStringValue(metadata_alias);
    }

    function getAssetRiskProperties() {
        const risk_column_names = [];
        metadataDefinitions.forEach((definition) => { // asset properties that are used in calculating risks
            if (definition.is_risk_property) {
                risk_column_names.push(definition.column_name);
            }
        });
        return risk_column_names; 
    }

    function getDetailsTableColumns2(assetRisks) { // For asset with risk type 2 (risk data with combinations)
        const columnKeys = getAssetRiskProperties(); // properties of asset that are used in risk calculation
        assetRisks.forEach((risk) => { // getting climate parameter names to use as column headers for all climate layers
            if (risk.probability != null && risk.parameter_value != null ) { //we don't want to show risk data for assets with no probability risk data
                columnKeys.push(risk.parameter);
            }
        }); 
        columnKeys.push('max_value');
        return columnKeys;
    }

    function getDetailsTableColumns(assetRisks) {
        const columnKeys = ['property'];
        if (selectedAssetLayerInfo.risk_type == 2) { // adding column placeholders to match up with risk type 2 second table
            const asset_risk_columns = getAssetRiskProperties(); // properties of asset that are used in risk calculation, only needed for riks type 2
            for (let i=0; i< asset_risk_columns.length-1; i++) {
                columnKeys.unshift('placeholder_' + i);
            }
        }
        assetRisks.forEach((risk) => columnKeys.push(risk.parameter)); // getting climate parameter names to use as column headers
        columnKeys.push('max_value');
        return columnKeys;
    }

    function appendClimateAndProbabilityRows(rows, assetRisk) { // generating row with climate layer value and probbility value
        
        const climate_parameter_header = assetRisk.parameter;
        // row showing climate layer value
        const parameter_value_row = {'property': 'climate value'};
        parameter_value_row[climate_parameter_header] = assetRisk.parameter_value == null ? 'N/A' : parseFloat(assetRisk.parameter_value);

        // row showing climate probability
        const probability_value_row = {'property': 'probability'};
        probability_value_row[climate_parameter_header] = (assetRisk.probability == null || assetRisk.parameter_value == null) ? 'N/A' : parseInt(assetRisk.probability);

        // row showing importance factor
        const importance_factor_row = {'property': 'importance factor'}; 
        importance_factor_row[climate_parameter_header] = (assetRisk.probability == null || assetRisk.parameter_value == null || assetRisk.importance_factor == null) ? 'N/A' : parseFloat(assetRisk.importance_factor);

        const initial_row = rows.length > 0 ? false : true;
        if (initial_row) {
            parameter_value_row['max_value'] = assetRisk.parameter_value == null ? 'N/A' : parseFloat(assetRisk.parameter_value);
            probability_value_row['max_value'] = (assetRisk.probability == null || assetRisk.parameter_value == null) ? 'N/A' : parseInt(assetRisk.probability);
            importance_factor_row['max_value'] = (assetRisk.probability == null || assetRisk.parameter_value == null || assetRisk.importance_factor == null) ? 'N/A' : parseFloat(assetRisk.importance_factor);
            rows.push(parameter_value_row);
            rows.push(probability_value_row);
            rows.push(importance_factor_row);
        } else {
            // getting max value for climaate value row
            const climate_value_row_index = rows.findIndex((details) => details.property == 'climate value');
            rows[climate_value_row_index] = { ...rows[climate_value_row_index], ...parameter_value_row};

            if (assetRisk.parameter_value != null) {
                if (rows[climate_value_row_index].max_value == 'N/A' || isNaN(rows[climate_value_row_index].max_value)) {
                    rows[climate_value_row_index].max_value = parseFloat(assetRisk.parameter_value);
                } else if (rows[climate_value_row_index].max_value < parseFloat(assetRisk.parameter_value)) {
                    rows[climate_value_row_index].max_value = parseFloat(assetRisk.parameter_value);
                }
            }

            // getting max value for probability row
            const probability_row_index = rows.findIndex((details) => details.property == 'probability');
            rows[probability_row_index] = { ...rows[probability_row_index], ...probability_value_row};

            if (assetRisk.parameter_value != null && assetRisk.probability != null) {
                if (rows[probability_row_index].max_value == 'N/A' || isNaN(rows[probability_row_index].max_value)) {
                    rows[probability_row_index].max_value = parseInt(assetRisk.probability);
                } else if (rows[probability_row_index].max_value < parseInt(assetRisk.probability)) {
                    rows[probability_row_index].max_value = parseInt(assetRisk.probability);
                }
            }

            // getting max value for importance factor row
            const importance_factor_row_index = rows.findIndex((details) => details.property == 'importance factor');
            rows[importance_factor_row_index] = { ...rows[importance_factor_row_index], ...importance_factor_row};

            if (assetRisk.parameter_value != null && assetRisk.probability != null && assetRisk.importance_factor != null) {
                if (rows[importance_factor_row_index].max_value == 'N/A' || isNaN(rows[importance_factor_row_index].max_value)) {
                    rows[importance_factor_row_index].max_value = parseFloat(assetRisk.importance_factor);
                } else if (rows[importance_factor_row_index].max_value < parseFloat(assetRisk.importance_factor)) {
                    rows[importance_factor_row_index].max_value = parseFloat(assetRisk.importance_factor);
                }
            }
            
        }

    }

    function appendImpactSeverityRows(currentRows, risk) {

        const impact_severity_attributes = metadataDefinitions.filter((column) => column.is_risk_property); //getting attributes used in impact risk calculation
        impact_severity_attributes.forEach((severity_attribute) => {
            const severity_column_name = severity_attribute.column_name + '_severity';
            const severity_risk_column_name = severity_attribute.column_alias + '_risk';
            const initial_row = currentRows.findIndex((details) => details.property == severity_risk_column_name) == -1 ? true : false;
            const severity_risk_row = {'property': severity_risk_column_name};
            
            // calculated impact severity risk
            const total_attribute_risk = Math.round(parseFloat(risk[severity_column_name]) * parseInt(risk.probability) * parseFloat(risk.importance_factor));
            severity_risk_row[risk.parameter] = total_attribute_risk;

            if (initial_row) {
                severity_risk_row['max_value'] = total_attribute_risk;
                currentRows.push(severity_risk_row);
            } else {
                const severity_risk_row_index = currentRows.findIndex((details) => details.property == severity_risk_column_name);
                currentRows[severity_risk_row_index] = { ...currentRows[severity_risk_row_index], ...severity_risk_row };
                if (currentRows[severity_risk_row_index].max_value < total_attribute_risk) {
                    currentRows[severity_risk_row_index].max_value = total_attribute_risk;
                }
            }
        });
    }

    function appendMaximumRisks(currentRows, risk) {
        const maximum_risk_row = { 'property': 'max_risk'}
        maximum_risk_row[risk.parameter] = parseInt(risk.max_risk);
        const initial_row = currentRows.findIndex((details) => details.property == 'max_risk') == -1 ? true : false;

        if (initial_row) {
            maximum_risk_row['max_value'] = parseInt(risk.max_risk);
            currentRows.push(maximum_risk_row);
        } else {
            const max_risk_row_index = currentRows.findIndex((details) => details.property == 'max_risk');
            currentRows[max_risk_row_index] = { ...currentRows[max_risk_row_index], ...maximum_risk_row};
            if (currentRows[max_risk_row_index].max_value < parseInt(risk.max_risk)) {
                currentRows[max_risk_row_index].max_value = parseInt(risk.max_risk);
            }
        }
    }

    function appendRiskCombinations(currentRows, asset_risk_properties, risk_combinations) {
    

        for (let i = 0; i < risk_combinations.length; i++) {
            
            const combination = risk_combinations[i];
            const climate_layer_parameter = combination.parameter;

            // Get row with the same risk combinations
            const combination_row_index = currentRows.findIndex((row) => { 
                let foundAssetProperties = 0;
                asset_risk_properties.forEach((risk_property) => {
                    if (row[risk_property] == combination[risk_property]) {
                        foundAssetProperties = foundAssetProperties + 1;
                    }
                });

                if (foundAssetProperties == asset_risk_properties.length){
                    return true;
                } else {
                    return false;
                }
            });

            // Append the asset risk according to the climate layer parameter
            if (combination_row_index != -1) {
                const current_combination_row = currentRows[combination_row_index];
                current_combination_row[climate_layer_parameter] = combination['risk'];
                if (combination['risk'] > current_combination_row['max_value']) { // max risk per combination row
                    current_combination_row['max_value'] = combination['risk'];
                }
            } else { // risk combination not yet added to table rows
                const row_id = currentRows.length + 1;
                const row = {'id': row_id};
                asset_risk_properties.forEach((risk_property) => {
                    row[risk_property] = combination[risk_property];
                });
                row[climate_layer_parameter] = combination['risk'];
                row['max_value'] = combination['risk']; 
                currentRows.push(row);

            }
        }
    }

    function getDetailsTableRows(assetRisks) {
        const rows = [];
        assetRisks.forEach((risk) => {
            appendClimateAndProbabilityRows(rows, risk);
            if (selectedAssetLayerInfo.risk_type == 1) {
                if (risk.parameter_value != null && risk.probability != null) {
                    appendImpactSeverityRows(rows, risk);
                }
                appendMaximumRisks(rows, risk);
            }
        });

        return rows;
    }

    function getDetailsTableRows2(assetRisks) {
        const rows = [];
        const asset_risk_properties = getAssetRiskProperties(); // Getting asset properties that are used in calculating risks

        assetRisks.forEach((risk) => { // going through each climate layer risk data
            const risk_combinations = risk.all_risk_combinations;
            if (risk.parameter_value != null && risk.probability != null) {
                appendRiskCombinations(rows, asset_risk_properties, risk_combinations);
            }
            

        });

        return rows;
    }

    function getRiskCellClass(params) {

        if (params.id.includes('_risk') && params.field != 'property') {
            const risk = parseFloat(params.value);

            if (risk >= 0 && risk < 3) {
                return 'risk_green';
            } else if (risk >= 3 && risk < 5) {
                return 'risk_blue';
            } else if (risk >= 5 && risk < 6) { // want to cater for decimal values like 5.5, 5.6 etc
                return 'risk_5';
            } else if (risk >=6 && risk < 10) {
                return 'risk_yellow';
            } else if (risk >= 10 && risk < 17) {
                return "risk_orange";
            } else if (risk >= 20) {
                return "risk_red";
            }
        } else if(params.field == 'property'){
            return "row_header"
        } else {
            return '';
        }

    }

    function getRiskType2CellClass(params) {


        const asset_risk_properties = getAssetRiskProperties(); // Getting asset properties that are used in calculating risks

        if (!asset_risk_properties.includes(params.field)) { // basically looking for columns which are climate layer risks

            const risk = parseFloat(params.value);
            if (risk >= 0 && risk < 3) {
                return 'risk_green';
            } else if (risk >= 3 && risk < 5) {
                return 'risk_blue';
            } else if (risk >= 5 && risk < 6) { // want to cater for decimal values like 5.5, 5.6 etc
                return 'risk_5';
            } else if (risk >=6 && risk < 10) {
                return 'risk_yellow';
            } else if (risk >= 10 && risk < 17) {
                return "risk_orange";
            } else if (risk >= 20) {
                return "risk_red";
            }
        } else if (asset_risk_properties.includes(params.field)) {
            return "row_header";
        } else {
            return '';
        }

    }

    function parseIDFHeader(data_parameter) {
        const parameter_parts = data_parameter.split("_");
        const idf_time = parseFloat(parameter_parts[1]);
        const idf_year = parseFloat(parameter_parts[2]);
        return "IDF " + idf_year + " year " + idf_time + " min";
    }

    function parseColumnName(header) {
    
        const customParameterMap = { // hardcoded this for now (basically mapping climate layer parameter property)
            "average_snow_depth": "Average Snow Depth (Annual)",
            "average_snow_depth_winter": "Average Snow Depth (Winter)",
            "average_tmax": "Average Tmax",
            "average_tmin": "Average Tmin",
            "max_precip_summer": "1 Day Max Precip (Summer)",
            "max_precip_spring": "1 Day Max Precip (Spring)",
            "max_precip_winter": "1 Day Max Precip (Winter)",
            "max_precip_annual": "1 Day Max Precip (Annual)",
            "max_precip_autumn": "1 Day Max Precip (Autumn)",
            "5_day_max_precip_annual": "5 Day Max Precip (Annual)",
            "tornadoes": "Tornadoes",
            "heat_wave": "Heat Waves (3 days > 30°C)",
            "lightning_density": "Lightning Density",
            "lightning": "Lightning",
            "days_greater_than_30_c": "Days > 30°C",
            "days_less_than_neg_25_c": "Days < -25°C",
            "storm_surge_residual": "Storm Surge (Residual)",
            "storm_surge_twl": "Storm Surge (TWL)",
            "mean_wave_height": "Mean Wave Height",
            "freeze_thaw_cycles": "Freeze Thaw Cycles",
            "hundred_year_flood": "100y Flooding",
            "coastal_sensitivity_index": "Coastal Sensitivity Index",
            "climate_moisture_index": "Climate Moisture Index",
            "max_value": "Max Value"
        }

        if (customParameterMap.hasOwnProperty(header)) {
            return customParameterMap[header];
        } else {
            return formatStringValue(header);
        }
    }

    function sortDetailsColumnKeys(tableColumnKeys) {
        const sortedKeys = tableColumnKeys.sort((key1, key2) => {
            const formatedKey1 = parseColumnName(key1);
            const formatedKey2 = parseColumnName(key2);
            if (formatedKey1 < formatedKey2) {
                return -1;
            } else if (formatedKey1 > formatedKey2) {
                return 1;
            }
            // values must be equal to key
            return 0;
        });

        return sortedKeys
    }

    function determineColumnWidth(header, isRiskColumn) {
        const headerLength = isRiskColumn ? 150 : header == "Max Value" ? 100 : header.length * 5.5;
        return headerLength;
    }



    const parseDetailRiskGridHeader = (columnKeys) => {

        // sorting columns in alphabetical order 
        const keysToFilter = ['property', 'max_value'];
    
        const placeholder_columns = columnKeys.filter((key) => key.includes('placeholder_')); // placeholder columns are blank empty columns
        const columnKeysToSort = columnKeys.filter((key) => !keysToFilter.includes(key) && !key.includes('placeholder_')); // we don't to sort blank placeholder columns and property and max value columns
        const sortedKeys = sortDetailsColumnKeys(columnKeysToSort);

        sortedKeys.unshift('property');
        placeholder_columns.forEach((key) => sortedKeys.unshift(key)); // adding placeholder columns back at respectively place
        sortedKeys.push('max_value'); // adding max value column at the end

        const headers = sortedKeys.map((header) => {
            const formatedHeaderName = header.startsWith('idf') ? parseIDFHeader(header) : parseColumnName(header);
            const propertyOrPlaceholderColumn = header == 'property' || header.includes('placeholder_') ? true : false;

            return {
                field: header,
                headerName: header.includes('placeholder_') ? '' : formatedHeaderName,
                renderCell: (params) => (
                    <>
                      {propertyOrPlaceholderColumn ? formatStringValue(params.value) : formatRiskValue(params.value)}
                    </>
                ),
                minWidth: 110,
                width: determineColumnWidth(formatedHeaderName, propertyOrPlaceholderColumn)
            }
        });
        return headers;
    }

    const parseDetailRiskType2GridHeader = (columnKeys) => {
        const asset_risk_properties = getAssetRiskProperties();

        // sorting columns in alphabetical order 
        const keysToFilter = ['contaminant', 'medium', 'receptors', 'max_value'];
        const columnKeysToSort = columnKeys.filter((key) => !keysToFilter.includes(key)); // geting all the columns we want to be arranged in alphabetical order
        const sortedKeys = sortDetailsColumnKeys(columnKeysToSort);

        sortedKeys.unshift('contaminant', 'medium', 'receptors');
        sortedKeys.push('max_value'); // adding max value column at the end

        const headers = sortedKeys.map((header) => {
            const formatedHeaderName = parseColumnName(header); 
            const headerIsRiskProperty = asset_risk_properties.includes(header) ? true : false;

            return {
                field: header,
                headerName: formatedHeaderName,
                renderCell: (params) => (
                    <>
                      {headerIsRiskProperty ? parseColumnName(params.value) : formatRiskValue(params.value)}
                    </>
                ),
                minWidth: 100,
                width: determineColumnWidth(formatedHeaderName, headerIsRiskProperty)
            }
        });
        return headers;
    }

    const renderMetadata = () => {
        const sortedMetadataKeys = sortAssetMetadataKeys(metadataDefinitions);

        return (
            <div style={metadataDiv}>
                {sortedMetadataKeys.map((metadataKey) => {
                    return (
                        <div key={metadataKey} style={{ width: '20%', padding: '5px', marginTop: '5px' }}>
                            <span style={metadataTitle}><Typography variant='overline'>{formatMetadataKey(metadataKey)}</Typography></span>
                            { metadataKey.includes('website') ?
                                <span style={metadataValue}><Typography style={{fontWeight: 'bold'}} variant='body2'><a href={selectedAsset[metadataKey]} target='_blank'>{selectedAsset[metadataKey]}</a></Typography></span>
                             :
                                metadataKey.includes('elevation') || metadataKey.includes('shoreline') ? 
                                <span style={metadataValue}><Typography style={{fontWeight: 'bold'}} variant='body2'>{selectedAsset[metadataKey]} m</Typography></span>
                                :
                                <span style={metadataValue}><Typography style={{fontWeight: 'bold'}} variant='body2'>{selectedAsset[metadataKey]}</Typography></span>
                             }
                        </div>
                    )
                })}

            </div>

        );
    }

    const customGridToolbar = () => {
        return (
            <GridToolbarContainer style={{display:'flex', justifyContent: "space-between", alignItems:'center' }}>
                <h6 style={{ fontWeight:'bold', marginLeft:'5px', marginTop:'10px'}}>Risk Scores for {selectedAsset.name}</h6>
                {selectedAssetLayerInfo.risk_type == 1 && (
                    <div>
                        <GridToolbarFilterButton style={{color:'#000'}} />
                        <GridToolbarExport printOptions={{ disableToolbarButton: true }} style={{color:'#000'}} csvOptions={{fileName: `${selectedAssetLayerInfo.title}_${selectedClimateScenario}_${selectedTimeframe}_${selectedAsset.name}`}}/>
                    </div>
                )}
                
            </GridToolbarContainer>
        )
    }

    const customGridToolbarForRiskType2 = () => {
        return (
            <GridToolbarContainer style={{display:'flex', justifyContent: "space-between", alignItems:'center' }}>
                <h6 style={{ fontWeight:'bold', marginLeft:'5px', marginTop:'10px'}}></h6>
                <div>
                    <GridToolbarFilterButton style={{color:'#000'}} />
                    <GridToolbarExport printOptions={{ disableToolbarButton: true }} style={{color:'#000'}} csvOptions={{fileName: `${selectedAssetLayerInfo.title}_${selectedClimateScenario}_${selectedTimeframe}_${selectedAsset.name}`}}/>
                </div>     
            </GridToolbarContainer>
        )
    }

    const renderRiskTable = () => {

        const asset_risk_data = allRiskData.filter((risk_data) => risk_data.asset_id == selectedAsset.id && risk_data.asset_layer_id == selectedAssetLayerInfo.id);
        const contains_risk_data = asset_risk_data.length > 0 ? true : false;

        const risk_details_headers = getDetailsTableColumns(asset_risk_data);
        const risk_details_rows = getDetailsTableRows(asset_risk_data);

        const risk_type_2_details_headers = selectedAssetLayerInfo.risk_type == 2 ? getDetailsTableColumns2(asset_risk_data) : [];
        const risk_type_2_details_rows = selectedAssetLayerInfo.risk_type == 2 ? getDetailsTableRows2(asset_risk_data) : [];

        const table1_height = selectedAssetLayerInfo.risk_type == 2 ? '32%' : '65%';

        // We need to check if probability is null or climate layer value is null and there is only risk data for that climate layer to show for which we don't want to show risk data table 
        const no_probability_data = asset_risk_data.length == 1 && asset_risk_data[0].probability == null ? true : false; // Mainly occurs for sea level change climate layer when considering asset elevation being greater than climate value
        const no_climate_layer_data = asset_risk_data.length == 1 && asset_risk_data[0].parameter_value == null ? true : false; 
        const hide_risk_data = no_probability_data || no_climate_layer_data ? true : false;

        if (!contains_risk_data) {
            return (
                <div>
                    <Typography variant="body1">There is no risk analysis data available for this asset.</Typography>
                </div>
            )
        } else {
            return (
                <div style={{ width: '100%', height: '100%'}}>

                    <div style={{ height: table1_height }}>
                        <DataGrid
                            sx={detailTablesStyle}
                            components={{ Toolbar: customGridToolbar }}
                            getRowId={(row) => row.property} 
                            rows={risk_details_rows} 
                            columns={parseDetailRiskGridHeader(risk_details_headers)}
                            getCellClassName={getRiskCellClass}
                            checkboxSelection={false}
                            disableSelectionOnClick={false}
                            showCellRightBorder
                            disableColumnSelector
                            disableColumnMenu
                            hideFooter
                        />

                    </div>

                    {selectedAssetLayerInfo.risk_type == 2 && hide_risk_data && (
                        <div style={{ height: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography variant="body1">There is no risk data available for the selected climate layer.</Typography>
                        </div>
                    )}

                    {selectedAssetLayerInfo.risk_type == 2 && !hide_risk_data && (
                        <div style={{ height: '50%'}}>
                            <DataGrid
                                sx={detailTablesStyle}
                                components={{ Toolbar: customGridToolbarForRiskType2 }}
                                rows={risk_type_2_details_rows} 
                                columns={parseDetailRiskType2GridHeader(risk_type_2_details_headers)}
                                getCellClassName={getRiskType2CellClass}
                                checkboxSelection={false}
                                disableSelectionOnClick={false} 
                                showCellRightBorder
                                disableColumnSelector
                                disableColumnMenu
                                hideFooter
                            />
                        </div>
                    )}
                    
                    
                </div>
                
            )

        }

    }
    

    return (
        <div style={detailedRiskModalStyle}>
            <Paper sx={{width: '70vw', height: '70vh'}}>
                <div style={details_header}>
                    <Typography variant='h6' style={{ color: "#fff"}}> Asset: {selectedAsset.name} </Typography>
                </div>

                <TabContext value={currentTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="detailstab">
                            <Tab label="Risk Analysis" value="1" />
                            <Tab label="Metadata" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1"style={{display:'flex', justifyContent:'left', alignItems:'center',padding:0, height: '100%'}}>{renderRiskTable()}</TabPanel>
                    <TabPanel value="2">{renderMetadata()}</TabPanel>
                </TabContext>
            </Paper>
        </div>
    )

}

export default DetailsModal;