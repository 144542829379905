import { height } from "@mui/system";

export const main_container = {
    position: 'absolute',
    top: '53%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '800px',
    backgroundColor: '#fff',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
};

export const main_container_last_step = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40vw',
    height: '40vh',
    maxHeight:'400px',
    backgroundColor: '#fff',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'

};
export const container_header = { 
    backgroundColor: "#000",
    height: '50px',
    padding: '15px 10px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
}

export const step_container = {
    display: 'flex',
    flexDirection: 'column',
    paddingTop:5,
    flexGrow:1,
    overflow: 'auto',
    padding:5
};

export const step_icon ={
    '& .Mui-completed': {color: '#4bbf73 !important'},
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':{
        color: 'black !important'
    },
}

export const step_header = {
    marginTop: 0,
    fontFamily:'Jost',
    fontWeight: 'bold',
    fontSize:'25px'
}

export const step_header_center = {
    fontWeight:'bold',
    textAlign:'center',
    fontSize:'17px',
    fontFamily:'Jost',
}

export const step_subheader = {
    // marginTop: '10px',
    fontFamily:'Jost',
    fontWeight: 'bold',
}

export const card ={
    display:'flex',
    flexDirection:'column',
    padding: '20px',
    border:'1px black solid',
    borderRadius:'10px',
    width:'450px',
    height:'450px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s'
}

export const centered_card={
    display:'flex',
    flexDirection:'column',
    padding:'20px',
    marginRight:'20px',
    height:'45vh',
    width:'20vw',
    justifyContent:'center',
    alignItems:'center',
    border:'1px black solid',
    borderRadius:'10px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s'
}

export const centered_card_margin = {
    marginTop: '50px'
}


export const view_results_container = {
    margin: '15px 10px',
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    overflow: 'auto',
    width: '95%'
}

export const menu_button = {
    background: '#FFF',
    borderRadius: '6px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.26)',
    border: '1px solid #FFF',
    fontSize: '9pt',
    color: '#000',
    transition: '.4s'
};

export const custom_menu_dropdown = {
    width: 'fit-content',
    padding: '5px 10px 5px'
}

export const menu_button_container = {
    margin: '10px 5px 10px'
}

export const menu_dropdown_options = {
    '.MuiFormControlLabel-label': {
        fontSize: '10pt'
    },
    '.MuiSvgIcon-root': {
        fontSize: '10pt'
    }
}

export const radio_button_options = {
    '.MuiFormControlLabel-label': {
        fontSize: '0.875rem'
    },
    '.MuiSvgIcon-root': {
        fontSize: '0.875rem',
        marginLeft: '10px'
    },
}

export const tree_column_flex = {
    height:'425px',
    display: 'flex',
    flexDirection: 'column',
    background: '#f6fafd',
    border:'.5px #868e96 solid',
    borderRadius:'10px',
    minWidth: '250px', 
    maxHeight: '35vh', 
    overflowY: 'auto'
}

export const table_container={
    width: '65vw',
    height: '45vh',
    border:'1px solid black',
    borderRadius:'10px',
    position:'relative',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    "&::-webkit-scrollbar": {
        width: 10,
        height: 10,
        backgroundColor: '#000',
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: '#000',
        borderRadius: 2
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: 2,
    },
    "&::-webkit-scrollbar-corner": {
        backgroundColor: 'rgba(0,0,0,0)',
        // borderRadius:
      }
}

export const table_header ={
    textAlign:'center',
    fontWeight:'bold',
    padding:'3px 10px 3px',
    color:'white',
    backgroundColor:'black',
    whiteSpace: 'nowrap'
}

export const table_placeholder ={
    fontWeight:'bold',
    color: '#f2f2f2',
    position:'absolute',
    top:'50%',left:'50%',
    transform: 'translate(-50% , -50%)',
    WebkitTransform: 'translate(-50%, -50%)',

}

export const tab_panel_container = {
    padding: '0px'
}

export const tree_node = {
    '.MuiFormControlLabel-label': {
        fontSize: '.95rem',
    },
    '.MuiSvgIcon-root': {
        fontSize: '1.3rem'
    }
}
 