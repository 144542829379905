export const tree_node = {
    '.MuiFormControlLabel-label': {
        fontSize: '.95rem',
        padding: '0px',
        margin: '5px'
    },
    '.MuiSvgIcon-root': {
        fontSize: '1.3rem'
    },
    '.MuiButtonBase-root': {
        padding: '0px'
    }
}

export const tree_chevron = {
    fontSize: '1.2rem',
    marginRight: '10px'
}

export const tree_row_flex = {
    display: 'flex',
    flexDirection: 'row'
}
