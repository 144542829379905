import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import authService from "../utils/AuthenticationService";
import SettingsNav from "../components/Settings/settingsNav";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';


const ProfilePage = () => {

    const [email, setEmail] = useState(null)
    const [name, setName] = useState(null)
    const [org, setOrg] = useState(null)

    async function getProfile(){
        let profile = await authService.getProfile()
        return profile
    }

    useEffect(async () => {
        let profile = await getProfile();
        setEmail(profile.email)
        setName(`${profile.given_name} ${profile.family_name}`)
        setOrg(profile.organization_id)
    },[]);


    return(
        <div style={{display:'flex',
                         flexDirection:'column',
                         backgroundColor:'#1a1a1a',
                         alignItems:'center',
                         width:'100%',
                         height:'100vh',
                         padding:0}}>
            <SettingsNav></SettingsNav>
            <div style={{display: 'flex',
                        flexDirection:'column',
                        alignItems: 'center',
                        justifyContent: 'left',
                        backgroundColor: 'white',
                        borderRadius: 10,
                        width:400, 
                        padding:20,
                        marginTop:200,}}>
                <div style={{display:'flex', flexDirection:'column',justifyContent:'center', width:'100%'}}>
                    <h2 style={{color: "black", 
                                width:'100%',
                                textAlign:'start',
                                fontWeight:'bold'}}> 
                            User Profile </h2>
                    <div id='user-profile' style={{display:'flex', flexDirection:'column', marginBottom:20}}>
                        <table style={{color: "black", width:'100%'}}>
                            <tbody>
                                <tr >
                                    <td><PersonIcon/></td>
                                    <td>{name}</td>
                                </tr>
                                <tr>
                                    <td><EmailIcon/></td>
                                    <td>{email}</td>
                                </tr>
                                <tr>
                                    <td><CorporateFareIcon/></td>
                                    <td>{org}</td>
                                </tr>
        
                            </tbody>
                        </table>
                        <a href="https://auth.woodweather.net/Account/Manage" target="_blank"
                                style={{color: '#343a40',
                                        size:10,
                                        textAlign:'end'}}
                                >
                                    Manage Account
                                </a>
                    </div>
                    <div id='user-preferences'>
                        <div style={{display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'left',
                                    width:'100%'}}>
                            <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                                <h3 style={{fontWeight:'bold', padding:0, margin:0}}>Settings</h3>
                            </div>
                            <table style={{color: "black"}}>
                                <tbody>
                                    <tr>
                                        <td>Setting</td>
                                        <td style={{textAlign:'end', color:'#bac0c0'}}>Preference</td>
                                    </tr>
                                    <tr>
                                        <td>Setting</td>
                                        <td style={{textAlign:'end', color:'#bac0c0'}}>Preference</td>
                                    </tr>
                                    <tr>
                                        <td>Setting</td>
                                        <td style={{textAlign:'end', color:'#bac0c0'}}>Preference</td>
                                    </tr>
                                </tbody>
                            </table>
                            <a style={{color: '#343a40',
                                    size:10,
                                    alignSelf:'end'}}>
                                Edit Preferences
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;