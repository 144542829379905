
import React from 'react';
import { Typography } from '@mui/material';

const legend_container = {
    position: 'absolute',
    bottom: '15%',
    right: '10px',
    zIndex: '1000',
    color: '#233845',
    fontSize: '12px',
    fontWeight: 700
}

const legend_items = {
    background: 'rgba(0, 0, 0, 0.5)',
    paddingRight: '10px',
    textAlign: 'center',
    width: '250px',
    height: '220px',
    flexDirection: 'column',
    display: 'flex'
}

const legend_title = {
    color: '#fff'
}

const legend_row = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    marginBottom: '5px'
}

const legend_row_subtitle = {
    color: "#fff",
    marginLeft: '10px'
}

const legend_item_green = {
    width: '55px',
    height: '20px',
    backgroundColor: 'rgba(168, 208, 141, 1)'
}

const legend_item_blue = {
    width: '55px',
    height: '20px',
    backgroundColor: 'rgba(0, 176, 240, 1)'
}

const legend_item_5 = {
    width: '55px',
    height: '20px',
    backgroundColor: 'rgba(255, 230, 153, 1)'
}

const legend_item_yellow = {
    width: '55px',
    height: '20px',
    backgroundColor: 'rgba(255, 255, 0, 1)'
}

const legend_item_orange = {
    width: '55px',
    height: '20px',
    backgroundColor: 'rgba(255, 119, 0, 1)'
}

const legend_item_red = {
    width: '55px',
    height: '20px',
    backgroundColor: 'rgba(255, 0, 0, 1)'
}

const RiskLegend = (props) => {
    return (
        <div style={legend_container}>
            <div style={legend_items}>
                <div style={legend_title}>
                    <Typography variant='overline'> Risk Assesment </Typography>
                </div>
                <div style={legend_row}>
                    <div style={legend_item_green}>1 - 2</div>
                    <Typography sx={legend_row_subtitle} variant='body1'>Negligible Risk</Typography>
                </div>
                <div style={legend_row}>
                    <div style={legend_item_blue}>3 - 4</div>
                    <Typography sx={legend_row_subtitle} variant='body1'>Low Risk</Typography>
                </div>

                <div style={legend_row}>
                    <div style={legend_item_5}>5</div>
                    <Typography sx={legend_row_subtitle} variant='body1'>Special Case</Typography>
                </div>

                <div style={legend_row}>
                    <div style={legend_item_yellow}>6 - 9</div>
                    <Typography sx={legend_row_subtitle} variant='body1'>Moderate Risk</Typography>
                </div>

                <div style={legend_row}>
                    <div style={legend_item_orange}>10 - 16</div>
                    <Typography sx={legend_row_subtitle} variant='body1'>Significant Risk</Typography>
                </div>

                <div style={legend_row}>
                    <div style={legend_item_red}>20 - 25</div>
                    <Typography sx={legend_row_subtitle} variant='body1'>Major Risk</Typography>
                </div>

            </div>
        </div>
    )
}

export default RiskLegend;