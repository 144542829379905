import React, {useState, useRef, useEffect,} from 'react';
import { MainContainer, MainBg, MainContent, MainH1, MainP, MainBtnWrapper, ArrowForward, ArrowRight } from './MainElements';
import { Button } from '../ButtonElement';
import { animateScroll as scroll } from 'react-scroll';
import { MapContainer, TileLayer, WMSTileLayer } from 'react-leaflet';
import Icon1 from '../../images/blueprintOutline.svg';
import Icon2 from '../../images/globeWeatherOutline.svg';
import Icon3 from '../../images/globeBookOutline.svg';
import { LayersContainer, LayersH1, LayersWrapper, LayersCard, LayersIcon, LayersH2, LayersP } from './LayersElements';
import authService from '../../utils/AuthenticationService';

const MainSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    const toggleBottom = () => {
        scroll.scrollToBottom();
    };

    const goToSignIn = () => authService.login();


    //demo map code
    const baseLayerUrlList = [
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
        "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}"
    ];
    // 
    const topoWmsUrl = 'https://ows.terrestris.de/osm/service?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetCapabilities';
    const sistemasURL = 'http://sistemas.gt4w.com.br/geoserver/processo_seletivo/wms';

    const demoMapConfig = { center: { lat: 47.57, lng: -52.865 }, zoom: 9 };
    
    return (
        <MainContainer id='home'>
            <MainBg>
            <MapContainer 
                center={demoMapConfig.center}
                zoom={demoMapConfig.zoom} 
                scrollWheelZoom={false}>

                <WMSTileLayer id='wmsLayer1'
                    url={'https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi'}
                    opacity={0.5}
                      >
                </WMSTileLayer>

                <TileLayer 
                    url={baseLayerUrlList[0]}
                    opacity={0.5}>
                </TileLayer>

                

            </MapContainer>
            </MainBg>
            <MainContent>
                <MainH1>Criva</MainH1>
                <MainP>
                    WSP's Climate Risk and Vulnerability Assessment Solution
                </MainP>
                <MainBtnWrapper>
                        <Button
                                onClick={goToSignIn}
                                onMouseEnter={onHover} 
                                onMouseLeave={onHover} 
                                primary='true' 
                                dark='true'
                                offset={-80}>
                            Sign In{hover ? <ArrowRight /> : <ArrowForward />}
                        </Button>
                </MainBtnWrapper>
            </MainContent>
        </MainContainer>   
    )
};

const Layers = () => {
    return (
        <LayersContainer id="Layers">
            <LayersH1>Demo</LayersH1>
            <LayersWrapper>
                <LayersCard>
                    <LayersIcon src={Icon1} />
                    <LayersH2>Layer  one</LayersH2>
                    <LayersP>MS Footprints</LayersP>
                </LayersCard>
                <LayersCard>
                    <LayersIcon src={Icon2} />
                    <LayersH2>Layer two</LayersH2>
                    <LayersP>WMS layer two</LayersP>
                </LayersCard>
                <LayersCard>
                    <LayersIcon src={Icon3} />
                    <LayersH2>Layer Three</LayersH2>
                    <LayersP>WMS climate data layer</LayersP>
                </LayersCard>
            </LayersWrapper>
        </LayersContainer>
    )
};

export { MainSection, Layers };

