import React, { useState, createContext, useEffect } from 'react';
import authService from "../AuthenticationService";


export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [userAuthenticated, setUserAuthentication] = useState(false);

    const authenticateUser = () => {
        setUserAuthentication(true);
    }

    const unAuthenticateUser = () => {
        setUserAuthentication(false);
    }

    useEffect(() => {
        // Fetching authentication
        let cancel = false;
        authService.getUser().then((user) => {
            if (user != null) {
                if (cancel) return;
                setUserAuthentication(true);
            }
        });

        return () => {
            cancel = true;
        }
    }, []);

    return (
        <AuthContext.Provider value={{ authenticateUser, unAuthenticateUser, userAuthenticated }}>
            {children}
        </AuthContext.Provider>
    )
}
